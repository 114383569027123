<template>
  <b-card
    title=""
  >
    <b-row class="justify-content-between">
      <b-col
        cols="2"
        class="mb-sm-1"
      >
        <b-form-group class="text-nowrap mb-0">

          <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
            style="min-width: 100px"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        md="6"
        sm="8"
        class="mb-1"
      >
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filterForSearch"
              type="search"
              placeholder="Type prefix or username to search"
              @keydown.enter="filterSearch"
            />
            <b-input-group-append>
              <b-button
                @click="filterSearch"
              >
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          ref="table"
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="reloadData"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy.sync="loading"
          :filter="filter"
          bordered
          :small="small"
        >
          <template #cell(edit)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              class="btn-icon"
              @click="editUrl(data.item)"
            >
              <feather-icon icon="Edit3Icon" />
            </b-button>
          </template>
          <template #cell(secretKey)="data">
            <div v-if="!data.item.disableApi">{{data.value}}</div>
            <div v-else>XXXXXXXXXXXXXXXXXXXXXX</div>
          </template>
          <template #cell(disableApi)="data">
            <div v-if="!data.value">
              NO
            </div>
            <div v-else>
              YES
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-modal
      v-model="modalUrl"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      title="ตั้งค่า API"
      button-size="sm"
      @ok="submitUrl"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-form-group
            label="WebsiteUrl"
            label-for="websiteUrl"
          >
            <validation-provider
              #default="{ errors }"
              name="websiteUrl"
              rules="required|url"
            >
              <b-form-input
                id="websiteUrl"
                v-model="urlForm.websiteUrl"
                autofocus
                :state="errors.length > 0 ? false:null"
                placeholder="WebsiteUrl"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="CallbackUrl"
            label-for="callbackUrl"
          >
            <validation-provider
              #default="{ errors }"
              name="callbackUrl"
              vid="callbackUrl"
              rules="required|url"
            >
              <b-form-input
                id="callbackUrl"
                v-model="urlForm.callbackUrl"
                placeholder="CallbackUrl"
                :state="errors.length > 0 ? false:null"
                @keydown.enter="submitUrl"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Suspend"
            label-for="Suspend"
          >
            <b-row class="align-items-center justify-content-start no-gutters">
              <b-col
                cols="12"
                md="4"
              >
                <b-form-input
                  disabled
                  :value="urlForm.disableApi ? 'Yes' : 'No'"
                />
              </b-col>
              <b-col
                cols="12"
                md="8"
                class="pl-md-1"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="text-nowrap"
                  @click.prevent="urlForm.disableApi = !urlForm.disableApi"
                >
                  Edit Status
                </b-button>
              </b-col>
            </b-row>

          </b-form-group>

        </b-form>
      </validation-observer>

    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BForm,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import agentService from '@/service/agentService'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BForm,
  },
  data() {
    return {
      small: true,
      loading: false,
      perPage: 25,
      pageOptions: [1, 5, 10, 25, 50, 100, 150, 250, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'createAt',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterForSearch: '',
      // filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'index',
          label: 'ลำดับ',
          sortable: true,
        },
        {
          key: 'prefix',
          label: 'Prefix',
          class: 'text-nowrap',
        },
        {
          key: 'username',
          label: 'agent',
          class: 'text-nowrap',
        },
        {
          key: 'secretKey',
          label: 'SecretKey',
          class: 'text-nowrap',
        },
        {
          key: 'websiteUrl',
          label: 'WebsiteUrl',
          class: 'text-nowrap',
        },
        {
          key: 'callbackUrl',
          label: 'CallbackUrl',
          class: 'text-nowrap',
        },
        'edit',
        {
          key: 'disableApi',
          label: 'Suspend',
          class: 'text-nowrap  text-center',
        },
      ],
      modalUrl: false,
      urlForm: {
        agent: '',
        callbackUrl: '',
        websiteUrl: '',
        disableApi: false,
      },
    }
  },
  mounted() {
    if (this.$can('update', 'api') === false) {
      this.fields.splice(6, 1)
      this.small = false
    }

    if (this.$store.state.app.isMaster(this.$store) === false) {
      this.fields.pop()
    }
  },
  methods: {
    filterSearch() {
      this.filter = this.filterForSearch
    },
    async reloadData() {
      this.loading = true
      const startRow = (this.currentPage - 1) * this.perPage
      const res = await agentService.getAuthen(`api/?filter=${this.filter}&limit=${this.perPage}&page=${startRow}&sortBy=${this.sortBy}&desc=${this.sortDesc}`)
      if (res.status === 200) {
        this.totalRows = res.data.count
        this.loading = false
        const { list } = res.data
        if (this.sortDesc === false) {
          let index = startRow + 1
          for (let i = 0; i < list.length; i += 1) {
            list[i].index = index
            index += 1
          }
        } else {
          let index = res.data.count
          for (let i = 0; i < list.length; i += 1) {
            list[i].index = index
            index -= 1
          }
        }
        return list
      }
      this.loading = false
      return []
    },
    editUrl(item) {
      this.urlForm.agent = item.username
      this.urlForm.callbackUrl = item.callbackUrl
      this.urlForm.websiteUrl = item.websiteUrl
      this.urlForm.disableApi = item.disableApi
      this.modalUrl = true
    },
    submitUrl(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate()
        .then(async success => {
          if (success) {
            this.$swal({
              dark: true,
              title: 'Are you sure?',
              text: 'คุณเเน่ใจว่าต้องการเเก้ไขข้อมูลใช่หรือไม่',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
              onOpen: () => {
                const btn = this.$swal.getConfirmButton()
                // eslint-disable-next-line no-shadow
                window.addEventListener('keypress', e => {
                  if (e.key === 'Enter') {
                    btn.click()
                  }
                })
              },
            })
              .then(async result => {
                if (result.value) {
                  const formSender = { ...this.urlForm }
                  if (formSender.websiteUrl.indexOf('https://') === -1) {
                    formSender.websiteUrl = `https://${formSender.websiteUrl}`
                  }
                  if (formSender.callbackUrl.indexOf('https://') === -1) {
                    formSender.callbackUrl = `https://${formSender.callbackUrl}`
                  }
                  const { agent } = this.urlForm
                  const res = await agentService.putAuthen(`api/?username=${agent}`, formSender)
                  if (res.status === 200) {
                    this.modalUrl = false
                    this.$refs.table.refresh()
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'success',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: res.data,
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: res.data,
                      },
                    })
                  }
                }
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

table {
  .sr-only {
    width: 0px !important;
  }

  .dropdown-menu {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.btn-group {
  .btn {
    padding: 5px;
  }
}

</style>
